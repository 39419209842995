import type { ReactElement } from "react";
import { ClickAwayListener as ClickAwayListenerExternal } from "@mui/base/ClickAwayListener";

type ClickAwayListenerProps = {
  onClickAway: () => void;
  children: ReactElement;
};

export default function ClickAwayListener({ children, onClickAway }: ClickAwayListenerProps) {
  return (
    <ClickAwayListenerExternal onClickAway={onClickAway}>{children}</ClickAwayListenerExternal>
  );
}
